import {
    HttpClient,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    AdvisorGoalMetric,
} from '#interfaces/dashboard/advisor-goal-metric.interface';
import { IDashboardCampaignEffectiveness, IDashboardCampaignRoi } from '#interfaces/dashboard/campaign.interface';
import {
    CategorySalesMetric,
} from '#interfaces/dashboard/category-sales-metric.interface';
import {
    LostOpportunityMetric,
} from '#interfaces/dashboard/lost-opportunity-metric.interface';
import {
    NumSalesAdvisor,
} from '#interfaces/dashboard/num-sales-advisor.interface';
import {
    OpportunityAdvisor,
} from '#interfaces/dashboard/opportunity-advisor.interface';
import {
    OpportunityDeparmentMetric,
} from '#interfaces/dashboard/opportunity-deparment-metric.interface';
import {
    ProspectsConvertMetric,
} from '#interfaces/dashboard/opportunity-prospects-metric';
import {
    OpportunityReasonMetric,
} from '#interfaces/dashboard/opportunity-reason-metric.interface';
import {
    OportunityResumeMetric,
} from '#interfaces/dashboard/opportunity-resume-metric.interface';
import {
    OpportunityWinLossReasonMetric,
} from '#interfaces/dashboard/opportunity-resume-reason-metric.interface';
import {
    OpportunityStepMetric,
} from '#interfaces/dashboard/opportunity-step-metric.interface';
import {
    PendingActivities,
} from '#interfaces/dashboard/pending-activities.interface';
import { IProductSoldComparativeMetric } from '#interfaces/dashboard/product-sold-comparative.interface';
import {
    ProductYearSalesMetric,
} from '#interfaces/dashboard/product-year-sales-metric.interface';
import { ProspectStepMetric } from '#interfaces/dashboard/prospect-step-metric.interface';
import { SalesByAdvisor } from '#interfaces/dashboard/sales-by-advisor.interface';
import {
    AdvisorGoalMetricResponse,
    CampaignEffectivenessMetricResponse,
    CampaignRoiMetricResponse,
    CategorySalesMetricResponse,
    GoalMetricsResponse,
    LostOpportunityMetricResponse,
    NumSalesAdvisorResponse,
    OpportunitiesResponse,
    OpportunityAdvisorResponse,
    OpportunityDeparmentMetricResponse,
    OpportunityReasonMetricsResponse,
    OpportunityResumeMetricResponse,
    OpportunityStepMetricResponse,
    PendingActivitiesResponse,
    ProductSoldComparativeResponse,
    ProductYearSalesMetricResponse,
    ProspectStepMetricResponse,
    ProspectsMetricResponse,
    SalesByAdvisorResponse,
    WinLossReasonsMetricResponse,
} from '#interfaces/http-response/responses/http-responses';
import { Opportunity } from '#interfaces/opportunity/opportunity.interface';
import { HeadersHttpService } from '#utils/http-headers/http-headers.service';
import { environment } from 'environments/environment';
import {
    BehaviorSubject,
    Observable,
    catchError,
    tap,
    throwError
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  // BranchOffices Selected
  private _currentBranchOffice: BehaviorSubject<number> = new BehaviorSubject(null);
  private _currentCampaign: BehaviorSubject<string> = new BehaviorSubject(null);
  private _goalMetricBranchOffice: BehaviorSubject<GoalMetricsResponse | null> = new BehaviorSubject(null);

  //Opportunity
  private _opportunityStepMetric: BehaviorSubject<OpportunityStepMetric[] | null> = new BehaviorSubject(null);
  private _opportunityFunnelStepMetric: BehaviorSubject<OpportunityStepMetric[] | null> = new BehaviorSubject(null);
  private _opportunityLossReasonMetric: BehaviorSubject<OpportunityReasonMetric[] | null> = new BehaviorSubject(null);
  private _opportunityWinReasonMetric: BehaviorSubject<OpportunityReasonMetric[] | null> = new BehaviorSubject(null);
  private _topOpportunitiesMetric: BehaviorSubject<Opportunity[] | null> = new BehaviorSubject(null);
  private _lostOpportunityMetric: BehaviorSubject<LostOpportunityMetric[] | null> = new BehaviorSubject(null);
  private _opportunityOriginMetric: BehaviorSubject<OpportunityReasonMetric[] | null> = new BehaviorSubject(null);
  private _opportunityResumeMetric: BehaviorSubject<OportunityResumeMetric[] | null> = new BehaviorSubject(null);
  private _opportunityDeparmentMetric: BehaviorSubject<OpportunityDeparmentMetric[] | null> = new BehaviorSubject(null);
  private _prospectsConvertMetric: BehaviorSubject<ProspectsConvertMetric | null> = new BehaviorSubject(null);
  private _prospectStepMetric: BehaviorSubject<ProspectStepMetric[] | null> = new BehaviorSubject(null);
  private _opportunityWinLossReasonResumeMetric: BehaviorSubject<OpportunityWinLossReasonMetric[] | null> = new BehaviorSubject(null);

  //Advisors
  private _pendingActivitiesMetric: BehaviorSubject<PendingActivities[] | null> = new BehaviorSubject(null);
  private _advisorGoalMetric: BehaviorSubject<AdvisorGoalMetric[] | null> = new BehaviorSubject(null);
  private _salesByAdvisor: BehaviorSubject<SalesByAdvisor[] | null> = new BehaviorSubject(null);
  private _numSalesByAdvisor: BehaviorSubject<NumSalesAdvisor[] | null> = new BehaviorSubject(null);
  private _opportunityAdvisor: BehaviorSubject<OpportunityAdvisor[] | null> = new BehaviorSubject(null);

  //Products
  private _productYearSalesMetric: BehaviorSubject<ProductYearSalesMetric[] | null> = new BehaviorSubject(null);
  private _categorySalesMetric: BehaviorSubject<CategorySalesMetric[] | null> = new BehaviorSubject(null);
  private _categoryNumProductSoldMetric: BehaviorSubject<CategorySalesMetric[] | null> = new BehaviorSubject(null);
  private _productSoldComparativeMetric: BehaviorSubject<IProductSoldComparativeMetric[] | null> = new BehaviorSubject(null);

  // Meta Stats
  private _campaignRoiMetric: BehaviorSubject<IDashboardCampaignRoi | null> = new BehaviorSubject(null);
  private _campaignEffectivenessMetric: BehaviorSubject<IDashboardCampaignEffectiveness | null> = new BehaviorSubject(null);

  private baseUrl: string = environment.baseUrl + 'Dashboard/';

  constructor(private httpClient: HttpClient, private httpHeaders: HeadersHttpService) { }

  // getters
  get currenBranchOfficeId() {
    const advisorBranchOfficeId = Number(Buffer.from(localStorage.getItem("idBranch") ?? "", "base64").toString());
    const mainBranchOfficeId = Number(localStorage.getItem('mainBranchOfficeId'));
    const branchOfficeIdPersistId = Number(localStorage.getItem('selectedBranchOfficeId'));
    const adminBranchOffice = branchOfficeIdPersistId === 0 ? mainBranchOfficeId : branchOfficeIdPersistId;

    const isAdmin = localStorage.getItem('rol') === 'SuperAdmin' || localStorage.getItem('rol') === 'Admin';
    if (isAdmin) {
      localStorage.setItem('selectedBranchOfficeId', adminBranchOffice.toString())
      return adminBranchOffice;
    }

    return advisorBranchOfficeId;
  }

  get campaignEffectivenessMetric$(): Observable<IDashboardCampaignEffectiveness> {
    return this._campaignEffectivenessMetric.asObservable();
  }

  get currentCampaign$(): Observable<string> {
    return this._currentCampaign.asObservable();
  }

  get campaignRoiMetric$(): Observable<IDashboardCampaignRoi> {
    return this._campaignRoiMetric.asObservable();
  }

  get prospectsConvertMetric$(): Observable<ProspectsConvertMetric> {
    return this._prospectsConvertMetric.asObservable();
  }

  get prospectStepMetric$(): Observable<ProspectStepMetric[]> {
    return this._prospectStepMetric.asObservable();
  }

  get opportunityDeparmentMetric$(): Observable<OpportunityDeparmentMetric[]> {
    return this._opportunityDeparmentMetric.asObservable();
  }

  get goaltBranchOffice$(): Observable<GoalMetricsResponse> {
    return this._goalMetricBranchOffice.asObservable();
  }

  get currentBranchOffice$(): Observable<number> {
    return this._currentBranchOffice.asObservable();
  }

  get opportunityAdvisor$(): Observable<OpportunityAdvisor[]> {
    return this._opportunityAdvisor.asObservable();
  }

  get salesByAdvisor$(): Observable<SalesByAdvisor[]> {
    return this._salesByAdvisor.asObservable();
  }

  get numSalesByAdvisor$(): Observable<NumSalesAdvisor[]> {
    return this._numSalesByAdvisor.asObservable();
  }

  get opportunityStepMetric$(): Observable<OpportunityStepMetric[]> {
    return this._opportunityStepMetric.asObservable();
  }

  get opportunityFunnelStepMetric$(): Observable<OpportunityStepMetric[]> {
    return this._opportunityFunnelStepMetric.asObservable();
  }

  get opportunityLossReasonMetric$(): Observable<OpportunityReasonMetric[]> {
    return this._opportunityLossReasonMetric.asObservable();
  }

  get opportunityWinReasonMetric$(): Observable<OpportunityReasonMetric[]> {
    return this._opportunityWinReasonMetric.asObservable();
  }

  get topOpportunitiesMetric$(): Observable<Opportunity[]> {
    return this._topOpportunitiesMetric.asObservable();
  }

  get lostOpportunityMetric$(): Observable<LostOpportunityMetric[]> {
    return this._lostOpportunityMetric.asObservable();
  }

  get opportunityOriginMetric$(): Observable<OpportunityReasonMetric[]> {
    return this._opportunityOriginMetric.asObservable();
  }

  get opportunityResumeMetric$(): Observable<OportunityResumeMetric[]> {
    return this._opportunityResumeMetric.asObservable();
  }

  get pendingActivitiesMetric$(): Observable<PendingActivities[]> {
    return this._pendingActivitiesMetric.asObservable();
  }

  get advisorGoalMetric$(): Observable<AdvisorGoalMetric[]> {
    return this._advisorGoalMetric.asObservable();
  }

  get productYearSalesMetric$(): Observable<ProductYearSalesMetric[]> {
    return this._productYearSalesMetric.asObservable();
  }

  get categorySalesMetric$(): Observable<CategorySalesMetric[]> {
    return this._categorySalesMetric.asObservable();
  }

  get categoryNumProductSoldMetric$(): Observable<CategorySalesMetric[]> {
    return this._categoryNumProductSoldMetric.asObservable();
  }

  get productSoldComparativeMetric$(): Observable<IProductSoldComparativeMetric[]> {
    return this._productSoldComparativeMetric.asObservable();
  }

  get winLossReasonResumeMetric$(): Observable<OpportunityWinLossReasonMetric[]> {
    return this._opportunityWinLossReasonResumeMetric.asObservable();
  }

  SetCurrentCampaign(id: string) {
    this._currentCampaign.next(id);
    this._currentCampaign.next(null);
  }

  SetCurrentBranchOffice(id: number) {
    this._currentBranchOffice.next(id);
    this._currentBranchOffice.next(null);
  }

  campaignEffectiveness(id: string): Observable<CampaignEffectivenessMetricResponse> {
    return this.httpClient.get<CampaignEffectivenessMetricResponse>(this.baseUrl + "CampaignEffectivenessMetric", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { id }
    })
      .pipe(
        tap((res) => {
          this._campaignEffectivenessMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  campaignRoiMetric(id: string): Observable<CampaignRoiMetricResponse> {
    return this.httpClient.get<CampaignRoiMetricResponse>(this.baseUrl + "CampaignRoiMetric", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { id }
    })
      .pipe(
        tap((res) => {
          this._campaignRoiMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  prospectsMetric(id: number, startDate: string, endDate: string): Observable<ProspectsMetricResponse> {
    return this.httpClient.get<ProspectsMetricResponse>(this.baseUrl + "ProspectConvertMetric", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { BranchOfficeId: id, startDate, endDate }
    })
      .pipe(
        tap((res) => {
          this._prospectsConvertMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunitiesDeparmentMetric(id: number): Observable<OpportunityDeparmentMetricResponse> {
    return this.httpClient.get<OpportunityDeparmentMetricResponse>(this.baseUrl + "DepartmentOpportunityMetrics", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { BranchOfficeId: id }
    })
      .pipe(
        tap((res) => {
          this._opportunityDeparmentMetric.next(res.data.filter(({ total }) => total > 0));
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunitiesWinLossResumeMetric(id: number): Observable<WinLossReasonsMetricResponse> {
    return this.httpClient.get<WinLossReasonsMetricResponse>(this.baseUrl + "ReasonWinAndLoss", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { BranchOfficeId: id }
    })
      .pipe(
        tap((res) => {
          this._opportunityWinLossReasonResumeMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunitiesResumeMetric(id: number): Observable<OpportunityResumeMetricResponse> {
    return this.httpClient.get<OpportunityResumeMetricResponse>(this.baseUrl + "WinAndLoss", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { BranchOfficeId: id }
    })
      .pipe(
        tap((res) => {
          this._opportunityResumeMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunitiesByAdvisor(id: number): Observable<OpportunityAdvisorResponse> {
    return this.httpClient.get<OpportunityAdvisorResponse>(this.baseUrl + "OpportunitiesByAdvisor", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { BranchOfficeId: id }
    })
      .pipe(
        tap((res) => {
          this._opportunityAdvisor.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  TopOpportunitiesMetric(branchId: number): Observable<OpportunitiesResponse> {
    return this.httpClient.get<OpportunitiesResponse>(this.baseUrl + "TopActiveOpportunitiesMetric", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { BranchOfficeId: branchId }
    })
      .pipe(
        tap((res) => {
          this._topOpportunitiesMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunityStepMetrics(start: string, end: string, branchId: number): Observable<OpportunityStepMetricResponse> {
    return this.httpClient.get<OpportunityStepMetricResponse>(this.baseUrl + "OpportunityStepMetrics", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { startDate: start, endDate: end, BranchOfficeId: branchId }
    })
      .pipe(
        tap((res) => {
          this._opportunityStepMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunityFunnelStepMetrics(start: string, end: string, branchId: number): Observable<OpportunityStepMetricResponse> {
    return this.httpClient.get<OpportunityStepMetricResponse>(this.baseUrl + "OpportunityFunnelStepMetrics", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { startDate: start, endDate: end, BranchOfficeId: branchId }
    })
      .pipe(
        tap((res) => {
          this._opportunityFunnelStepMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunityLossReasonMetrics(start: string, end: string, branchId: number): Observable<OpportunityReasonMetricsResponse> {
    return this.httpClient.get<OpportunityReasonMetricsResponse>(this.baseUrl + "OpportunityLossReasonMetrics", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { startDate: start, endDate: end, BranchOfficeId: branchId }
    })
      .pipe(
        tap((res) => {
          this._opportunityLossReasonMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunityWinReasonMetrics(start: string, end: string, branchId: number): Observable<OpportunityReasonMetricsResponse> {
    return this.httpClient.get<OpportunityReasonMetricsResponse>(this.baseUrl + "OpportunityWinReasonMetrics", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { startDate: start, endDate: end, BranchOfficeId: branchId }
    })
      .pipe(
        tap((res) => {
          this._opportunityWinReasonMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  LostOpportunityMetrics(year: number, branchId: number): Observable<LostOpportunityMetricResponse> {
    return this.httpClient.get<LostOpportunityMetricResponse>(this.baseUrl + "LostOpportunitiesByYear", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { Year: year, BranchOfficeId: branchId }
    })
      .pipe(
        tap((res) => {
          this._lostOpportunityMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunityOriginMetrics(branchId: number): Observable<OpportunityReasonMetricsResponse> {
    return this.httpClient.get<OpportunityReasonMetricsResponse>(this.baseUrl + "OpportunityOriginMetrics", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { BranchOfficeId: branchId }
    })
      .pipe(
        tap((res) => {
          this._opportunityOriginMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  NumSalesByAdvisor(year: number, branchId: number): Observable<NumSalesAdvisorResponse> {
    return this.httpClient.get<NumSalesAdvisorResponse>(this.baseUrl + "NumSalesFromYear", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { Year: year, BranchOfficeId: branchId }
    })
      .pipe(
        tap((res) => {
          this._numSalesByAdvisor.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  SalesByAdvisor(year: number, branchId: number): Observable<SalesByAdvisorResponse> {
    return this.httpClient.get<SalesByAdvisorResponse>(this.baseUrl + "SalesFromYear", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { Year: year, BranchOfficeId: branchId }
    })
      .pipe(
        tap((res) => {
          this._salesByAdvisor.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  AdvisorPendingActivities(branchOfficeId: number): Observable<PendingActivitiesResponse> {
    return this.httpClient.get<PendingActivitiesResponse>(this.baseUrl + "AdvisorPendingActivities", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { branchId: branchOfficeId }
    })
      .pipe(
        tap((res) => {
          this._pendingActivitiesMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  AdvisorGoalMetrics(date: string, branchId: number): Observable<AdvisorGoalMetricResponse> {
    return this.httpClient.get<AdvisorGoalMetricResponse>(this.baseUrl + "AdvisorGoalMetrics", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { Date: date, BranchOfficeId: branchId }
    })
      .pipe(
        tap((res) => {
          this._advisorGoalMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  ProductYearSalesMetric(year: number, branchId: number): Observable<ProductYearSalesMetricResponse> {
    return this.httpClient.get<ProductYearSalesMetricResponse>(this.baseUrl + "ProductYearSales", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { Year: year, BranchOfficeId: branchId }
    })
      .pipe(
        tap((res) => {
          this._productYearSalesMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  CategoryTotalSalesMetric(startDate: string, endDate: string, branchId: number, step: number): Observable<CategorySalesMetricResponse> {
    return this.httpClient.get<CategorySalesMetricResponse>(this.baseUrl + "CategoryTotalSalesMetrics", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { StartDate: startDate, EndDate: endDate, BranchOfficeId: branchId, OpportunityStepId: step }
    })
      .pipe(
        tap((res) => {
          this._categorySalesMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  CategoryNumProductSold(startDate: string, endDate: string, branchId: number, step: number): Observable<CategorySalesMetricResponse> {
    return this.httpClient.get<CategorySalesMetricResponse>(this.baseUrl + "CategoryNumProductSold", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { StartDate: startDate, EndDate: endDate, BranchOfficeId: branchId, OpportunityStepId: step }
    })
      .pipe(
        tap((res) => {
          this._categoryNumProductSoldMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  ProductSoldComparative(categoryId = 0, year = new Date().getFullYear()): Observable<ProductSoldComparativeResponse> {
    const body = { year } as any;
    if (categoryId) body.categoryId = categoryId;

    return this.httpClient.get<ProductSoldComparativeResponse>(this.baseUrl + "ProductSoldComparative", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: body
    })
      .pipe(
        tap((res) => {
          this._productSoldComparativeMetric.next(res.data);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  GlobalGoalMetric(BranchOfficeId: number): Observable<GoalMetricsResponse> {
    const response = this.httpClient.get<GoalMetricsResponse>(this.baseUrl + "GlobalGoalMetric", { headers: this.httpHeaders.getHeadersPrivate(), params: { BranchOfficeId } })
      .pipe(
        tap(resp => {
          this._goalMetricBranchOffice.next(resp)
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );

    return response;
  }

  ProspectStepsMetric(startDate: string, endDate: string, BranchOfficeId?: number) {
    var params = { StartDate: startDate, EndDate: endDate }
    if (BranchOfficeId) {
      params['BranchOfficeId'] = BranchOfficeId
    }
    const response = this.httpClient.get<ProspectStepMetricResponse>(this.baseUrl + "ProspectStepMetric", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: params
    })
      .pipe(
        tap(resp => {
          this._prospectStepMetric.next(resp.data)
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );

    return response;
  }
}