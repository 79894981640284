import {
    HttpClient,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BranchOffice } from '#interfaces/company/branch-office.interface';
import {
    ApiResponse,
} from '#interfaces/http-response/responses/api-response.interface';
import {
    BranchesOfficeResponse,
    BranchOfficeDetailResponse,
} from '#interfaces/http-response/responses/http-responses';
import { cleanObjectStringValues } from '#root/http/utils';
import { HeadersHttpService } from '#utils/http-headers/http-headers.service';
import { environment } from 'environments/environment';
import {
    BehaviorSubject,
    catchError,
    Observable,
    of,
    switchMap,
    tap,
    throwError
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BranchOfficeService {
  private _branches: BehaviorSubject<BranchesOfficeResponse | null> = new BehaviorSubject(null);

  private baseUrl: string = environment.baseUrl + "BranchOffice/";

  constructor(private httpClient: HttpClient, private httpHeaders: HeadersHttpService) { }

  // getter
  get branches$(): Observable<BranchesOfficeResponse> {
    return this._branches.asObservable();
  }

  GetBranchOffice(page: number, size: number, name: string = ' ', sort: string = '', column: string = '', all: boolean = false): Observable<BranchesOfficeResponse> {
    return this.httpClient.get<BranchesOfficeResponse>(this.baseUrl + "GetAll", {
      headers: this.httpHeaders.getHeadersPrivate(),
      params: { pageNumber: page, pageSize: size, parameter: name, order: sort, column: column, All: all }
    })
      .pipe(
        tap((res) => {
          this._branches.next(res);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  updateBranchOffice(branch: BranchOffice): Observable<BranchOfficeDetailResponse> {
    const response = this.httpClient.put<BranchOfficeDetailResponse>(this.baseUrl + "Update/" + branch.id, cleanObjectStringValues(branch), { headers: this.httpHeaders.getHeadersPrivate() })
      .pipe(
        switchMap(resp => {
          return of(resp);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
    return response;
  }

  createBranchOffice(branch: BranchOffice): Observable<BranchOfficeDetailResponse> {
    const response = this.httpClient.post<BranchOfficeDetailResponse>(this.baseUrl + "Create", cleanObjectStringValues(branch), { headers: this.httpHeaders.getHeadersPrivate() })
      .pipe(
        switchMap(resp => {
          return of(resp);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
    return response;
  }

  Delete(id: number): Observable<ApiResponse> {
    const response = this.httpClient.delete<ApiResponse>(this.baseUrl + "Delete/" + id, { headers: this.httpHeaders.getHeadersPrivate() })
      .pipe(
        switchMap(resp => {
          return of(resp)
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse)
        })
      );
    return response;
  }
}
