import {
    HttpClient,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    CategoriesResponse,
    CategoryDetailResponse,
} from '#interfaces/http-response/responses/http-responses';
import { cleanObjectStringValues } from '#root/http/utils';
import { Category } from '#root/interfaces/product/category.interface';
import { HeadersHttpService } from '#utils/http-headers/http-headers.service';
import { environment } from 'environments/environment';
import {
    BehaviorSubject,
    Observable,
    catchError,
    map,
    of,
    switchMap,
    take,
    tap,
    throwError
} from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CategoryService {
	// Private
	private _categories: BehaviorSubject<CategoriesResponse | null> =
		new BehaviorSubject(null);
	private _category: BehaviorSubject<CategoryDetailResponse | null> =
		new BehaviorSubject(null);

	constructor(
		private _httpClient: HttpClient,
		private _httpHeaders: HeadersHttpService
	) { }
	/**
	 * Getter for category detail
	 */
	get category$(): Observable<CategoryDetailResponse> {
		return this._category.asObservable();
	}
	/**
	 * Getter for categories
	 */
	get categories$(): Observable<CategoriesResponse> {
		return this._categories.asObservable();
	}

	/**
	 * Get categories method
	 */
	getCategories(page: number, size: number, search: string = '', sort: string = '', column: string = '', all: boolean = false): Observable<CategoriesResponse> {
		return this._httpClient
			.get<CategoriesResponse>(`${environment.baseUrl}Category/GetAll`, {
				params: { PageNumber: page, PageSize: size, Parameter: search, Order: sort, Column: column, All: all },
				headers: this._httpHeaders.getHeadersPrivate(),
			})
			.pipe(
				tap((categories) => {
					this._categories.next(categories);
				})
			);
	}

	/**
	 * Get category by id
	 */
	getCategoryById(id: number): Observable<Category> {
		return this._categories.pipe(
			take(1),
			map((categories) => {
				// Find the category
				const category = categories.data.find((item) => item.id === id) || null;
				// Update the category
				this._categories.next(categories);

				// Return the category
				return category;
			}),
			switchMap((category) => {
				if (!category) {
					return throwError('Could not found category with id of ' + id + '!');
				}

				return of(category);
			})
		);
	}

	/**
	 * Create new category
	 */
	createCategory(category: Category): Observable<CategoryDetailResponse> {
		return this._httpClient
			.post<CategoryDetailResponse>(
				`${environment.baseUrl}Category/Create`,
				cleanObjectStringValues(category),
				{ headers: this._httpHeaders.getHeadersPrivate() }
			)
			.pipe(
				map((newCategory) => {
					// Return the new category
					return newCategory;
				}),
				catchError((err) => {
					return throwError(() => err as HttpErrorResponse);
				})
			);
	}

	/**
	 * Update category
	 *
	 * @param id
	 * @param category
	 */
	updateCategory(id: number, category: Category): Observable<CategoryDetailResponse> {
		return this._httpClient
			.put<CategoryDetailResponse>(
				`${environment.baseUrl}Category/Update/${id}`,
				cleanObjectStringValues(category),
				{ headers: this._httpHeaders.getHeadersPrivate() }
			)
			.pipe(
				switchMap((updatedCategory) => {
					return of(updatedCategory);
				}),
				catchError((err) => {
					return throwError(() => err as HttpErrorResponse);
				})
			);
	}

	/**
	 * Delete the category
	 *
	 * @param id
	 */
	deleteCategory(id: number): Observable<boolean> {
		return this._httpClient
			.delete<CategoryDetailResponse>(
				`${environment.baseUrl}Category/Delete/${id}`,
				{
					headers: this._httpHeaders.getHeadersPrivate(),
				}
			)
			.pipe(
				map((response) => {
					return response.succeeded;
				}),
				catchError((err) => {
					return throwError(() => err as HttpErrorResponse);
				})
			);
	}
}
