import { Route } from '@angular/router';

import { InitialDataResolver } from '#root/app.resolvers';
import { AuthGuard } from '#root/core/auth/guards/auth.guard';
import { NoAuthGuard } from '#root/core/auth/guards/no-auth.guard';
import { LayoutComponent } from '#root/core/layout/layout.component';

import {
  DashboardByRoleResolver,
} from './modules/admin/dashboard/dashboard.resolver';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: "", pathMatch: "full", redirectTo: "dashboard" },

  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: "signed-in-redirect", pathMatch: "full", redirectTo: "dashboard" },

  // Auth routes for guests
  {
    path: "",
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "forgot-password",
        loadChildren: () =>
          import(
            "#root/modules/auth/forgot-password/forgot-password.module"
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: "reset-password",
        loadChildren: () =>
          import("#root/modules/auth/reset-password/reset-password.module").then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: "sign-in",
        loadChildren: () =>
          import("#root/modules/auth/sign-in/sign-in.module").then(
            (m) => m.AuthSignInModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-out",
        loadChildren: () =>
          import("#root/modules/auth/sign-out/sign-out.module").then(
            (m) => m.AuthSignOutModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("#root/modules/admin/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        canActivate: [DashboardByRoleResolver],
      },
      {
        path: "crm",
        loadChildren: () =>
          import("#root/modules/admin/crm/crm.module").then((m) => m.CrmModule),
      },
      {
        path: "ecommerce",
        loadChildren: () =>
          import("#root/modules/admin/ecommerce/ecommerce.module").then(
            (m) => m.EcommerceModule
          ),
      },
      {
        path: "inventory",
        loadChildren: () =>
          import("#root/modules/admin/inventory/inventory.module").then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: "globalConfig",
        loadChildren: () =>
          import("#root/modules/admin/global-config/global-config.module").then(
            (m) => m.GlobalConfigModule
          ),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("#root/modules/admin/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: "rootcloud",
        loadChildren: () =>
          import("#root/modules/admin/rootcloud/rootcloud.module").then(
            (m) => m.RootcloudModule
          ),
      },
      {
        path: "error/403",
        loadChildren: () =>
          import(
            "#root/modules/miscellaneous/error/error-403/error-403.module"
          ).then((m) => m.Error403Module),
      },
      {
        path: "error/404",
        loadChildren: () =>
          import(
            "#root/modules/miscellaneous/error/error-404/error-404.module"
          ).then((m) => m.Error404Module),
      },
      {
        path: "error/500",
        loadChildren: () =>
          import(
            "#root/modules/miscellaneous/error/error-500/error-500.module"
          ).then((m) => m.Error500Module),
      },
      {
        path: "**",
        loadChildren: () =>
          import(
            "#root/modules/miscellaneous/error/error-404/error-404.module"
          ).then((m) => m.Error404Module),
      },
    ],
  },
];
