import pkg from "../../package.json";

export const environment = {
  version: pkg.version,
  hmr: false,
  production: true,
  baseUrl: "https://crm-motors.azurewebsites.net/api/v1/",
  signalRUrl: "https://crm-motors.azurewebsites.net/api/v1/notification",
  googleMapskey: "AIzaSyDaXyX_LvkVMSM0xId6uJIwqfu-Euw-gYA",
  sentryDSN: "https://b95f0311257f417dbedb21699fd90187@o4505172191608832.ingest.sentry.io/4505172192395264",
};
